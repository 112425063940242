<template>
    <div>
        <v-card height="100%">
            <v-banner sticky color="white" class="pt-1 pb-1" single-line>
                <v-card-title class="text-h5 pt-0 pb-0 pl-0">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeDialogVersions"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    {{ quote.name }}
                </v-card-title>
            </v-banner>
            <v-card-text>
                <v-row no-gutters class="mt-3">
                    <v-col cols="2" class="d-flex">
                        <p class="mr-2 text-subtitle-1 font-weight-black">
                            Scope:
                        </p>
                    </v-col>
                    <v-col cols="10" class="d-flex  flex-wrap">
                        <p
                            style="margin-top: 5px; font-size: medium; font-weight: 500;"
                        >
                            {{ costInfo.reference }}
                        </p>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="2" class="d-flex">
                        <p class="mr-2 text-subtitle-1 font-weight-black">
                            Scope Type:
                        </p>
                    </v-col>
                    <v-col cols="10" class="d-flex  flex-wrap">
                        <p
                            style="margin-top: 5px; font-size: medium; font-weight: 500;"
                        >
                            {{ costInfo.category }}
                        </p>
                    </v-col>
                </v-row>
                <v-data-table
                    :headers="headersCosts"
                    class="elevation-0 mt-1"
                    :items="items"
                    hide-default-footer
                    :mobile-breakpoint="0"
                    :disable-pagination="true"
                >
                    <template v-slot:[`item.versionNumber`]="{ item }">
                        <p class="my-0 text-capitalize">
                            {{ item.versionNumber }}
                        </p>
                    </template>
                    <template v-slot:[`item.generatedOn`]="{ item }">
                        {{ formatDate2(item.generatedOn) }}
                    </template>
                    <template v-slot:[`item.engineer`]="{ item }">
                        {{ getUserName(item.engineer) }}
                    </template>
                    <template v-slot:[`item.rejectedOn`]="{ item }">
                        {{ formatDate2(item.rejectedOn) }}
                    </template>
                    <template v-slot:[`item.rejectionReason`]="{ item }">
                        {{ item.rejectionReason }}
                    </template>
                    <template v-slot:[`item.name`]="{ item }">
                        <v-btn
                            color="transparent"
                            elevation="0"
                            small
                            class="ma-1 text-capitalize text--darken-2  pa-2"
                        >
                            <span
                                @click.stop="
                                    openFile(item.name ? item.name : '')
                                "
                                style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer;"
                                >{{
                                    item.versionNumber
                                        ? `V${item.versionNumber}`
                                        : ''
                                }}</span
                            >
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'RejectionTable',
    props: {
        quote: {
            type: Object,
            required: true,
            default: () => {},
        },
        costInfo: {
            type: Object,
            required: true,
            default: () => {},
        },
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        users: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data: () => ({
        headersCosts: [
            {
                text: 'VERSION',
                value: 'versionNumber',
                class: 'grey lighten-3',
                width: 10,
            },
            {
                text: 'DATE SENT',
                value: 'generatedOn',
                class: 'grey lighten-3',
                sortable: false,
                width: 30,
            },
            {
                text: 'ENGINEER',
                value: 'engineer',
                class: 'grey lighten-3',
                sortable: false,
                width: 30,
            },
            {
                text: 'DATE REJECTED',
                value: 'rejectedOn',
                class: 'grey lighten-3',
                sortable: false,
                width: 30,
            },
            {
                text: 'REASON FOR REJECTION',
                value: 'rejectionReason',
                class: 'grey lighten-3',
                sortable: false,
                width: 190,
            },
            {
                text: 'FILE',
                value: 'name',
                class: 'grey lighten-3',
                align: 'left',
                sortable: false,
                width: 10,
            },
        ],
    }),
    methods: {
        formatDate(date) {
            return moment
                .unix(date.seconds || date._seconds)
                .format('YYYY-MM-DD')
        },
        formatDate2(date) {
            if (!date) return ''

            if (typeof date === 'object' && (date._seconds || date.seconds)) {
                return moment
                    .unix(date._seconds || date.seconds)
                    .format('YYYY-MM-DD')
            } else if (
                typeof date === 'string' &&
                moment(date, moment.ISO_8601, true).isValid()
            ) {
                return moment(date).format('YYYY-MM-DD')
            } else {
                console.warn('Invalid date format:', date)
                return ''
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        closeDialogVersions() {
            this.$emit('closeDialogVersions')
        },
        openFile(itemName) {
            this.$emit('openFile', itemName)
        },
        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
    },
}
</script>

<style>
.v-data-table__wrapper {
    max-height: 50vh;
    overflow-y: auto;
}

.v-data-table__row {
    height: 64px;
}

.v-data-table__wrapper thead tr {
    position: sticky;
    top: 0;
    z-index: 10;
}
</style>
